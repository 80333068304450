a {
  cursor: pointer !important;
}
table > thead{
    background-color: #516BFF;
    color: white;
    border: 1px solid #e0e0e0;
  }

  th{
    border-radius: 0px;
    font-size: 18px;
    font-weight: 400;
  }

  table > tbody{
    border: 1px solid #e0e0e0;
    background-color: #FAFAFA;
    color: #4A4A4A;
    font-weight: 300;
  }

  table > tbody > tr{
    font: 1re
  }

  table > tbody > tr > td{
    border-left: 1px solid #e0e0e0;
  }

  .search-box{
    width: 25% !important;
    margin: 0 0px 10px 75% !important;
  }

  .search-box > input{
    border-bottom: 1px solid #9e9e9e !important;
    width: 85% !important;
    height: 1.5rem !important;
    margin: 0px !important;
  }

  .search-box > img{
    width: 8% !important;
    padding: 0px;
  }

  button.download {
    margin-top: 10px;
  }

  .file-name-text{
    margin:0px !important;
    padding-left: 5px !important;
    height: 30px;
    text-align: left;
  }

  .file-name-text > p{
    margin-top: 0.5em;
  }

  .file-name-tag{
    height: 25px;
    line-height: 25px;
    border-radius: 5px;
  }

  .file-name-img{
    margin:0px;
    height: 30px;
    text-align: right;
    padding-left: 15px !important;
  }

  .file-name-img > img{
    height: 100%;
  }


  /* The Modal (background) */
  .modal {
      display: block; /* Hidden by default */
      max-height:100% !important;
      position: fixed; /* Stay in place */
      z-index: 1000; /* Sit on top */
      padding-top: 100px; /* Location of the box */
      left: 0;
      top: 0;
      width: 100%; /* Full width */
      height: 100vh; /* Full height */
      overflow: auto; /* Enable scroll if needed */
      background-color: rgb(0,0,0); /* Fallback color */
      background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

  /* Modal Content */
  .modal-content {
      background-color: #fefefe;
      margin: auto;
      padding: 20px;
      border: 1px solid #888;
  }

  .modal-content > div > div > p {
    font-weight: 300;
    margin-left: 20px;
    width: 100%;
    margin: 0px;
  }

  .modal-content > div.label {
    font-weight: 400;
    margin: 0px;
    padding: 0px;
  }

  .modal-content.form {
    padding: 2%;
  }

  .modal-content > form {
    padding: 2%;
  }

  .close {
      color: #aaaaaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
  }

  .close:hover,

  .close:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
  }

  .btn-flat:focus {
    background: transparent;
  }

  .form-section{
    padding-bottom: 15px;
    padding-top: 15px;
    border-bottom: #e0e0e0 solid 1px;
  }

  .form-section>.inputs{
    padding-bottom: 15px;
    padding-top: 15px;
  }

  .inputs > div.form-input{
    min-height: 88px;
  }

  input{
    height: 2.5rem;
  }
  div.upload-button{
    margin-top: 0.25rem;
  }

  table > tbody >tr > td {
    text-align: center;
    padding : 8px 5px;
  }

  div.question.disabled {
    border-color: #9e9e9e;
    color: #9e9e9e
  }

  div.question.disabled {
    border-color: #9e9e9e;
    color: #9e9e9e
  }

  div.question.disabled > div > div > [type="checkbox"]+label:before{
    border-color: #9e9e9e;
    cursor: default;
  }
  div.question.disabled > div > div > [type="checkbox"]+label{
    cursor: default;
  }

  button.record-form{
    /*background-color: rgb(51, 133, 193);*/
    background-color: rgb(78, 140, 185)
  }

  button.record-form.disabled{
    /*background-color: rgb(51, 133, 193);*/
    background-color: #9e9e9e
  }

  .record-form{
    /*background-color: rgb(51, 133, 193);*/
    background-color: rgb(78, 140, 185);
    margin-left: 2px;
  }

  button.record-form:focus{
    /*background-color: rgb(51, 133, 193);*/
    background-color: rgb(78, 140, 185, 0.7)
  }

  th.record-form{
    /*background-color: rgb(51, 133, 193);*/
    background-color: #245c82
  }

  div.search-form > input.search-container{
    border: lightgrey 1px solid;
    border-radius: 5px;
    display: flow-root;
    padding-left: 5px;
    padding-right: 5px;
  }
  div.search-form > div.records-container{
    border: lightgrey 1px solid;
    border-radius: 5px;
    display: flow-root;
    padding: 5px;
  }

  div.search-results > div.result{
    overflow: hidden;
    line-height: 3.0rem;
    height: 3.0rem;
    margin: 0px;
    padding: 0px;
  }

  div.search-results > div.result > button{
    line-height: 2.5rem;
    height: 2.5rem;
    font-size: 0.9rem;
    margin: 0px;
    padding: 0px;
    border: white 1.5px solid;
    border-radius: 5px;
  }

  div.search-results > hr{
    background-color:#e6e6e6;
    height: 1px;
    border: 0;
    padding: 0px;
    margin: 0px;
    width: 100%;
  }

  tr.selected{
    background-color: #ffc14b !important;
  }

  .clickable{
    cursor: pointer;
  }

  .dismissed{
    background: #fd3636;
    border-radius: 5px;
    padding: 5px;
    color: white;
  }

  .confirmed{
    background: #3fc140;
    padding: 5px;
    border-radius: 5px;
    color: white;
  }


  div > div.col.s4.confimation-label{
    text-align: right;
    font-weight: bold;
  }

  div > div.col.s3.card-label{
    text-align: right;
    font-weight: bold;
  }
  .btn, .btn-large, .btn-flat{
    margin-right: 2px;
  }

  .form-input > p.breadcrumbs{
    font-weight: 300;
    width: auto;
    padding: 5px;
    border-radius: 2px;
    color: white;
    background: #d41700;
    display: inline-block;
    margin-left: 2px;
  }

  div.question.disabled > div > div > [type="checkbox"]:checked+label:before{
    top: -4px;
    left: -5px;
    width: 12px;
    height: 22px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #26a69a;
    border-bottom: 2px solid #26a69a;
    cursor: default;
  }

  .error{
    color: red;
    text-align: center;
    animation: blink 1s;
    margin-top: 5px;
    margin-bottom: 0px;
  }

  @keyframes blink {
      0% {opacity: 0}
      49%{opacity: 0}
      50% {opacity: 1}
  }

  div.possible-match > .detail-section{
    padding-bottom: 1.5em;
  }
  div.possible-match > .detail-section > .single-detail{
    padding-bottom: 0.5em;
  }


  /* PPF Capital Component Styles*/
  input.v-input{
    font-size: xx-large;
    font-weight: 200;
  }

  hr.ppf-hr{
    border:0;
    border-top: #f1f1f1 1px solid;
  }

  .card .card-content .card-title {
    font-weight: 200;
    font-size: 2.25rem;;
  }

  button.ppf-primary-button{
    margin-top: 10px;
    background-color: #3F51B5;
    color: #fff;
    border: none;
    border-radius: 2px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    font-size: 1rem;
    font-weight: 300;
    padding: 0 2rem;
    text-transform: uppercase;
  }

  div.ppf-primary-button{
    margin-top: 10px;
    background-color: #3F51B5;
    color: #fff;
    border: none;
    border-radius: 2px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    font-size: 1rem;
    font-weight: 300;
    padding: 0 2rem;
    text-transform: uppercase;
  }


  button.ppf-flat{
    border: none;
    background-color: transparent;
  }

  button.ppf-danger-button{
    margin-top: 10px;
    background-color: rgba(241, 63, 68);
    color: #fff;
    border: none;
    border-radius: 2px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    font-size: 1rem;
    font-weight: 300;
    padding: 0 2rem;
    text-transform: uppercase;
  }

  button.ppf-nav-button{
    margin-top: 10px;
    background-color: #2196F3;
    color: #fff;
    border: none;
    border-radius: 2px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    font-size: 1rem;
    font-weight: 300;
    padding: 0 2rem;
    text-transform: uppercase;
  }

  span.ppf-control-title {
    font-family: roboto;
    font-weight: 200;
    font-size: large;
    text-align: right;
  }

  input.padded-right{
    margin-right: 0.75rem;
  }

  button.padded-right{
    margin-right: 0.75rem;
  }

  div.padded-right{
    margin-right: 0.75rem;
  }

  div.form-input > div.tab-selector {
    border: 0px #e6e6e6 solid;
    border-radius: 5px;
    color: white;
    background-color: #3f51b5;
  }

  div.form-input > div.tab-selector >div.bordered{
    border-right: 1px #e6e6e6 solid;
  }

  div.form-input > div.tab-selector >div.option{
    padding: 0.4rem;
    cursor: pointer;
  }
  div.form-input > div.tab-selector >div.option.selected{
    padding: 0.4rem;
    background-color: #5f72da;
  }

  div.col div.ppf-section.col {
    background-color: white;
    padding: 25px;
    margin: 3%;
    width: 95%;
    padding: 5%;
  }

  @media only screen and (max-device-width : 767px) {
    /* Styles */
    .side-nav li>a>i,
    .side-nav li>a>[class^="mdi-"],
    .side-nav li>a li>a>[class*="mdi-"],
    .side-nav li>a>i.material-icons{
      margin: 0 0 0 0;
      width: auto;
    }
  }

  .switch label input[type=checkbox]:checked+.lever:after {
      background-color: #4051b5;
  }
  .switch label input[type=checkbox]:checked+.lever {
    background-color: #4051b5ab;
}